.footer-main-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}


.footer-icons {
  display: flex;
  justify-content: center;
}

.footer-icons i {
  font-size: 25px;
  cursor: pointer;
}
.footer-icons i:hover {
  color: black;
}

.bi-twitter {
  color: #ffffff;
}

.bi-facebook {
  color: #ffffff;
}

.bi-instagram {
  color: #ffffff;
}

.optiwise-solution{
  color: black;
  text-decoration: none;
}
.optiwise-solution:hover{
  color: #ffffff;
}
