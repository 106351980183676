.tweet-box{
    width: auto;
    height: 253px;
    background-image: url("../assets/get-involved/t-6.png");
    background-size: cover;
    color: #FFFFFF;
}
.signup-box{
    width: auto;
    height: 253px;
    background-image: url("../assets/get-involved/s-3.jpg");
    background-size: cover;
    color: #FFFFFF;
}
.contact-box{
    width: auto;
    height: 253px;
    background-image: url("../assets/get-involved/c-1.jpg");
    background-size: cover;
    color: #FFFFFF;
}