.black_stone_1_bg {
  background-image: url("../assets/events/black_stone_1.jpg");
  background-repeat: "no-repeat";
  background-size: cover;
  width: "350px";
  height: "570px";
  color: "blue";
}

.aurat_march_bg {
  background-image: url("../assets/events/aurat_march.jpg");
  background-repeat: "no-repeat";
  background-size: cover;
  width: "350px";
  height: "570px";
  color: "blue";
}

.teesri_dhun_bg {
  background-image: url("../assets/events/teesri_dhun.jpg");
  background-repeat: "no-repeat";
  background-size: cover;
  width: "570px";
  height: "470px";
  color: "blue";
}

.live_with_jpp_bg {
  background-image: url("../assets/events/live_with_jpp.png");
  background-repeat: "no-repeat";
  background-size: cover;
  width: "350px";
  height: "570px";
  color: "blue";
}

.jpp_omct_bg {
  background-image: url("../assets/events/jpp_omct.jpg");
  background-repeat: "no-repeat";
  background-size: cover;
  width: "350px";
  height: "570px";
  color: "blue";
}

.overlay-box {
  background-color: #f5f5f5;
  height: 570px;
  background-repeat: no-repeat;
  background-size: cover;
}

.overlay-box:hover .desc,
.overlay-box:focus .desc {
  opacity: 1;
}

.overlay-box .desc {
  opacity: 0;
  min-width: 100%;
  min-height: 225px;
  font-size: 1rem;
  width: 100%;
  height: 570px;
  padding: 30px 25px 20px;
  transition: all 0.3s ease;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
}
