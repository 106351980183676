.banner-main-div{
    background-image: url("../assets/b-3.webp");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 450px;
    
}
@media screen and (max-width:567px){
    .banner-main-div{
        height: 140px;
    }
}
.banner-content-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.banner-text{
    font-family: 'Poppins', sans-serif;
    /* font-size: 48px; */
    /* font-weight: bolder; */
    color: white;
}


.b-video{
    width: 100%;
    /* height: 300px; */
    max-height: max-content;
}


.paragraph{
    display: flex;
    flex-direction: column;
    gap:5px;
    color: white;
    align-items: start;
    font-family: 'Poppins', sans-serif;
}
.paragraph h3{
    font-size:32px ;
}