.scroll {
    color: #fff;
    margin-top: 100px;
  }
  
  #toTopBtn {
    position: fixed;
    bottom: 40px;
    right: 28px;
    z-index: 98;
    padding: 21px;
    background-color: #B8704F;
  
    /* width: 60px;
      height: 60px;
      border-radius: 100%; */
  }
  
  .js .cd-top--fade-out {
    opacity: 0.5;
  }
  
  .js .cd-top--is-visible {
    visibility: visible;
    opacity: 1;
  }
  
  .js .cd-top {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s, background-color 0.3s;
  }
  
  .cd-top {
    position: fixed;
    bottom: 20px;
    bottom: var(--cd-back-to-top-margin);
    right: 20px;
    right: var(--cd-back-to-top-margin);
    display: inline-block;
    height: 40px;
    height: var(--cd-back-to-top-size);
    width: 40px;
    width: var(--cd-back-to-top-size);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
    background: url(https://res.cloudinary.com/dxfq3iotg/image/upload/v1571057658/cd-top-arrow.svg)
      no-repeat center 50%;
    background-color: #B8704F;
    background-color: hsla(
      var(--cd-color-3-h),
      var(--cd-color-3-s),
      var(--cd-color-3-l),
      0.8
    );
  }